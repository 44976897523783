import { query } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AsapService, Global } from 'asap-crud';
import { CommentsModule } from '../comments/comments.module';

@Component({
  selector: 'app-feed',
  templateUrl: './feed.component.html',
  styleUrls: ['./feed.component.scss']
})

export class FeedComponent implements OnInit {

  public loading = true;
  public report: any;
  public counters = [
    {
      title: 'Posts',
      value: 'posts',
      icon: 'flaticon-windows',
      action: () => {
        this.download();
      }
    },
    {
      title: 'Likes',
      value: 'likes',
      icon: 'flaticon-like'
    },
    {
      title: 'Comments',
      value: 'comments',
      icon: 'flaticon-megaphone'
    },
    {
      title: 'Views',
      value: 'views',
      icon: 'flaticon-view'
    }
  ];
  public channel;
  public searchChannel;
  public searchCategory;
  public id: string;
  public item: any;
  public filter;
  post_id: any;

  constructor(
    public global: Global,
    public service: AsapService
  ) {
     
  }

  public ngOnInit() {
    this.loadData(); 
    this.channels();  
  } 

  public loadData() {

    let params = '';

    if (this.searchChannel) {
        params = `?channel=${this.searchChannel}`;
    }
    if (this.searchCategory) {
        if (params != '') params += '&'; else params += '?';
        params += `category=${this.searchCategory}`;
    }
    this.service.basePath = 'api/v2/report'
    this.service.entityName = `feed${params}`;

    this.service.getResources().subscribe(
      (response) => {
        if (response.success) {
          this.loading = false;

          this.report = response.data;

          this.counters[0].value = response.data.posts;
          this.counters[1].value = response.data.likes;
          this.counters[2].value = response.data.comments;
          this.counters[3].value = response.data.views;

        }
        else {
          this.global.toastService.error({
            title: 'Erro:',
            message: response.message,
            position: 'topRight'
          });
        }
      },
      (_error) => {
        this.global.toastService.error({
          title: 'Erro:',
          message: 'Ocorreu um erro ao gerar o relatório!',
          position: 'topRight'
        });
      }
    );
  }

  public download() {
    this.global.startWaiting('Gerando arquivo!');
    this.service.getResources({ query: 'feed?excel=open' }).subscribe(
      (response) => {
        if (response.success) {
          this.global.download('feed-list.xls', response.data);
        }
        else {
          this.global.toastService.error({
            title: 'Erro:',
            message: response.message
          });
        }

        this.global.stopWaiting();
      },
      (_error) => {
        this.global.stopWaiting();
        this.global.toastService.error({
          title: 'Erro:',
          message: 'Ocorreu um erro ao gerar o relatório!'
        });
      }
    );
  }

  public generateReport(item: any) {
    this.service.basePath = 'api/v2/report'
    this.service.entityName = `feed/view?id=${item.post_id}`;
    this.global.startWaiting('Gerando arquivo...');
    this.service.getResources().subscribe(
      (response) => {
        this.global.download('medias.xls', response.data);
        this.global.stopWaiting();
      },
      (error) => {
        this.global.alert("Aconteceu um erro ao gerar o arquivo");
        console.log('error', error)
        this.global.stopWaiting();
      }
    )
      
  }

  public generateGeneral() {
    let params = '';

    if (this.searchChannel) {
        params = `?channel=${this.searchChannel}`;
    }
    if (this.searchCategory) {
        if (params != '') params += '&'; else params += '?';
        params += `category=${this.searchCategory}`;
    }
    this.service.basePath = 'api/v2/report'
    if (params != '') {
      this.service.entityName = `feed${params}&excel=open`;
    }else{
      this.service.entityName = `feed?excel=open`;
    }

    this.global.startWaiting('Gerando arquivo...');
    this.service.getResources().subscribe(
      (response) => {
        this.global.download('medias.xls', response.data);
        this.global.stopWaiting();
      },
      (error) => {
        this.global.alert("Aconteceu um erro ao gerar o arquivo");
        console.log('error', error)
        this.global.stopWaiting();
      }
    )
      
  }

  public channels() {	
    this.loading = true;
    this.service.basePath = 'api/v2/admin'
		this.service.entityName = "channel";
		this.service.getResources().subscribe((response) => {
			this.channel = response.data;
		});
	}

}

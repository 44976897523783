import { Component, Output, EventEmitter, Input, SimpleChange } from '@angular/core';
import { AsapService } from 'asap-crud';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import {map, startWith} from 'rxjs/operators';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-filters',
    templateUrl: './filters.component.html',
    providers: [ AsapService ]
})

export class FiltersComponent {
    @Input() clear:boolean = false;
    @Output() onSearch = new EventEmitter();
    @Output() currentClear: EventEmitter <boolean> = new EventEmitter();


    //public clear:number = 0;
    public fields;
    public ufs;
    public cities: any;
    public filteredCities: Observable<string[]>;
    public myControl = new FormControl();

    public filter:any = {};
    public fieldDataType:any;

    public faixasAnosIntervaloDe:any= [];
    public faixasAnosIntervaloAte:any= [];
    public FaixasAnos: any= {};
    public showFieldDataTytpe = false;

    constructor(public service:AsapService) {
        this.loadFields();
        this.loadUF();
        
    }




    ngOnChanges(changes: SimpleChange) {
        console.log(changes)
        if(this.clear==true) {
            console.log(this.filter)
            this.filter = {};
            this.clear = false;
            this.loadFields();
        }
    }


    
    public SetFaixasAnos(){
        console.log(this.FaixasAnos)
    }   
    public filtraCidades(){
        this.filteredCities = this.myControl.valueChanges
        .pipe(
          startWith(''),
          map(value => this._filter(value))
        );
    }
    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();
        return this.cities.filter(option => option.nome.toLowerCase().includes(filterValue));
      }
    public loadFields() {

        this.FaixasAnos = {
            from:'',
            to:'',
            type:'date'
        }

        this.fieldDataType = localStorage.getItem('fieldDataType')?localStorage.getItem('fieldDataType'):1;
        
        //this.faixasAnosIntervaloDe = Array(50).fill(1).map((x, y) => (new Date().getFullYear()-50) - y);
        //this.faixasAnosIntervaloAte =  this.faixasAnosIntervaloDe.map((x, y) => (x+1));
        this.service.basePath = "api/v2";
        this.service.entityName = "admin/user/form";
        this.service.getResources().subscribe((response) => {
            console.log("[FiltersComponent]",response);
            this.fields = response.data.map((item) => {
               // if(item.select_filter==1) {
                    if (item.type == "select") {
                        if ((item.options.length == 0) || (item.options[0] == 'base')) {
                            this.service.basePath = "api/v2";
                            this.service.entityName = "admin/user/form/values/" + item.name;
                            this.service.getResources().subscribe((response) => {
                                console.log("[FiltersComponent.FieldValues]",response);
                                item.options = response.data;
                            });
                        }
                    } else if ((item.type == "number") || (item.type == "date")) {
                        this.setFieldDataTytpe(item.name);
                        this.filter[item.name] = {
                            from:'',
                            to: '',
                            type:item.type
                        };
                    }
                    
                    return item;
               // }
            });
            console.log("fields",this.fields)
            console.log(this.filter)

        });
    }

    public loadUF() {
        console.log(this.filter)
        this.service.http.get("https://servicodados.ibge.gov.br/api/v1/localidades/estados").subscribe((response) => {
            console.log("[loadUF]",response);
            this.ufs = response;
        });
    }

    public loadCities(event) {
        //this.filter.cidade = null;
        let uf = this.ufs.find((item) => item.sigla == event.value);
        if (uf) {
            this.service.http.get("https://servicodados.ibge.gov.br/api/v1/localidades/estados/" + uf.sigla + "/municipios").subscribe((response) => {
                //console.log("[loadCities]",response);
                this.cities = response;
                this.filtraCidades();
                this.search()
            });  
        }
    }
    public setFieldDataTytpe(campo) {
         localStorage.setItem('fieldDataType', this.fieldDataType);
         this.filter[campo] = this.FaixasAnos;
        console.log(campo)
    }

    public search() {
        let tmp_filter = {};
        console.log(this.filter)
        if (Object.keys(this.filter).length > 0) {
            if (this.filter) {
           
                Object.entries(this.filter).map((item:any[],val) => {
      
                    if(item[1]) {
                       console.log('type',item, item[1], typeof item[1])
                        if((typeof item[1]==='string') && item[1].length) {
                           // tmp_filter[item[0]] = item[1].replace('-','');
                           tmp_filter[item[0]] = item[1];
                            //VERIFICA SE O CAMPO É UM RANGE de/ate
                        } else if(typeof item[1]==='object')  {
                            console.log("length",item[1].length)
                            if(item[1].length>1) {
                                tmp_filter[item[0]] = item[1][0] +'[AND]'+item[1][1];
                            } else {
                                tmp_filter[item[0]] = item[1][0];
                            }
                            //VERIFICA SE O CAMPO É UM RANGE DE NUMEROS INTEIROS
                            if(item[1].type=='number') {
                                if(item[1].from.length) {
                                    tmp_filter[item[0]] = item[1].from +'⋅'+item[1].to;
                                } 

                                //VERIFICA SE O CAMPO É UM RANGE DE DATAS
                                } else if(item[1].type=='date') { 
                                    //VERIFICA SE AS DUAS POSIÇÕES DO RANGE ESTAO PREENCHIDAS (DE/ATE)
                                    if(item[1].from.toString().length && item[1].to.toString().length) {
                                        //VERIFICA SE É COMPOSTO APENAS POR 4 DIGITOS(ANO) OU DATA COMPLETA (DE/ATE)
                                        if(item[1].from.toString().length==4) {
                                            tmp_filter[item[0]] = item[1].from+'-01-01'+'|'+item[1].to+'-12-31';
                                        } else {
                                            tmp_filter[item[0]] = new DatePipe('en').transform(item[1].from, 'yyyy-MM-dd')+'|'+new DatePipe('en').transform(item[1].to, 'yyyy-MM-dd');
                                        }

                                    //SE APENAS UMA, É UMA DATA UNICA (DE/DE)
                                    } else if(item[1].from.toString().length && (item[1].to.toString().length==0)) {
                                    tmp_filter[item[0]] = new DatePipe('en').transform(item[1].from, 'yyyy-MM-dd')+'|'+new DatePipe('en').transform(item[1].from, 'yyyy-MM-dd');
                                }
                                console.log(tmp_filter[item[0]])
                                
                                }
                            }
                    }
                })
            
               
            }
            console.log(tmp_filter)
            this.onSearch.emit(tmp_filter);
            this.currentClear.emit(true);

        }
        else {
            this.onSearch.emit(null);
        }
    }

}

import { Component, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/services/shared.service';
import * as moment from 'moment';
import { UserService } from 'src/app/services/user.service';
import { AsapService } from 'asap-crud';
import { isObject } from 'util';

@Component({
    selector: 'app-percentual-item',
    templateUrl: './percentual-item.component.html',
    providers: [ AsapService /*UserService*/ ]
})

export class PercentualItemComponent implements OnChanges {

    @Input() path;
    @Input() templateID;
    @Input() detailIcon;
    @Input() percentLabel = "";
    @Input() detailTitle;

    @Output() detailClick = new EventEmitter();

    @Input() report;
    
    public params;

    public years = [];
    public fields = [];

    public topData;
    public reportData;

    public selectedYear;
    public selectedField;


    public loading = true;

    public months = {
        "00":"",
        "01":"JANEIRO",
        "02":"FEVEREIRO",
        "03":"MARÇO",
        "04":"ABRIL",
        "05":"MAIO",
        "06":"JUNHO",
        "07":"JULHO",
        "08":"AGOSTO",
        "09":"SETEMBRO",
        "10":"OUTUBRO",
        "11":"NOVEMBRO",
        "12":"DEZEMBRO"
    };

    constructor(
        public router: Router,
        public service: SharedService,
        public userService: UserService
    ) {
        if (router.url.indexOf("/report/percentual/") >= 0) {
            let path:any = router.url.split("/report/percentual/").join("");
            this.findReport(path);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if ((changes.path) && (this.path)) {
            this.findReport(this.path);
        }
        if (changes.report && this.report) {
            this.selectedYear = moment().format("YYYY");
            this.loadYear(this.selectedYear);
        }
    }

    public findReport(path) {
        path = path.split("/");
        
        this.report = path[0];

        delete(path[0]);
        
        this.params = path.join("/");

        this.selectedYear = moment().format("YYYY");

        this.report = this.service.percentualReports.find(report => (this.report == report.link));
        if (this.report) {
            this.loadYear(this.selectedYear);
        }
        else {
            if (!this.path) {
                this.router.navigate(["/"]);
            }
        }
    }

    public changeValue(array,field) {
        if (isObject(array)) {
            array = Object.values(array);
        }
        let result = array.map((item) => {
            if ((field) && (field != '')) {
                item.value = item[field];
                item.percentual = null;
                if (item.beforeLabel) {
                    if ((typeof item.beforeLabel) == "string") {
                        item.beforeValue = item.beforeLabel;
                    }
                    else {
                        item.beforeValue = item.beforeLabel[field];
                    }
                }
                if (item.Detail) {
                    item.Detail = this.changeValue(item.Detail,field);
                }
                if (item.detail) {
                    item.detail = this.changeValue(item.detail,field);
                }
                return item;
            }
            else {
                return item;
            }
        });
        return result;
    }

    public processArray(array) {
        if (array) {
            if (isObject(array)) {
                array = Object.values(array);
            }
            let total = array.reduce((total,item) => {
                return {
                    value: parseFloat(total.value) + parseFloat(item.value)
                };
            });

            let result = array.map((item) => {
                if ((typeof item.label) == "string") {
                    if (item.label.indexOf("-") >= 0) {
                        item.month = item.label.split("-");
                        item.month = this.months[item.month[1]] + "/" + item.month[0];
                    }
                }
                if (!item.percentual) {
                    item.percentual = ((100*item.value)/total.value);
                }
                if (item.Detail) {
                    item.Detail = this.processArray(item.Detail).sort((a,b) => {
                        if (a.value > b.value) return -1;
                        if (a.value < b.value) return +1;
                        return 0;
                    });
                }
                if (item.detail) {
                    item.detail = this.processArray(item.detail).sort((a,b) => {
                        if (a.value > b.value) return -1;
                        if (a.value < b.value) return +1;
                        return 0;
                    });
                }
                return item;
            });

            return result;
        }
        return null;
    }

    public loadYear(year = undefined) {
        this.selectedYear = year;
        this.loading = true;
        this.years = [];
        if (this.report) {
            this.service.basePath = "api/v21"
            this.service.entityName = this.report.api + ((this.params)?this.params:'');
            this.service.getResources({router:year}).subscribe((response) => {
                this.topData = response.data.topData;
                
                this.fields = response.data.fields;
                if (this.fields) {
                    if (this.fields.length > 0) {
                        this.selectedField = this.fields[0];
                    }
                }

                for (let i=response.data.startAt; i <= response.data.endAt; i++) {
                    this.years.push(i);
                }

                this.reportData = this.processArray(response.data.report);
                this.loading = false;
            });
        }
    }

    public loadDetail(data) {
        if (data.Detail) {
            data.Detail = null;
        }
        else {
            data.loading = true;
            if (this.report) {
                this.service.basePath = "api/v21"
                this.service.entityName = this.report.api + ((this.params)?this.params:'') + "/" + this.selectedYear + "/" + data.label;
                this.service.getResources().subscribe((response) => {
                    data.Detail = this.changeValue(response.data.report,this.selectedField);
                    data.Detail = this.processArray(data.Detail).sort((a,b) => {
                        if (a.value > b.value) return -1;
                        if (a.value < b.value) return +1;
                        return 0;
                    });
                    data.toTemplate = response.data.toTemplate;
                    data.loading = false;
                });
            }
        }
    }

    public changeField(field) {
        let array = this.reportData;
        this.reportData = null;
        array = this.changeValue(array,field);
        this.reportData = this.processArray(array);
        this.selectedField = field;            
    }

}

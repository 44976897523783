import { environment } from './../environments/environment';
import { Component, OnInit } from '@angular/core';
import { AuthService } from './services/auth.service';
import { Router, RouterEvent, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { Global } from 'asap-crud';



@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

    constructor(
        public auth: AuthService,
        public global: Global,
        public router: Router
    ) {

    }

    ngOnInit() {
        //localStorage.setItem('apiURL',"https://apiv3.plataformaasap.com.br");
        localStorage.setItem('apiURL',environment.apiURL);
        localStorage.setItem("adonisURL","https://io.plataformaasap.com.br");

        this.restartData(location.href);

        this.router.events.subscribe((event: RouterEvent) => {
            this.navigationInterceptor(event)
        });

    }

    public restartData(url) {
        this.auth.params = this.getQueryParams(url);
        if (this.auth.params && this.auth.params.token) {
            sessionStorage.clear();
            localStorage.clear();
            localStorage.setItem('apiURL',environment.apiURL);
            localStorage.setItem("adonisURL","https://io.plataformaasap.com.br");
        }
    }

    public getQueryParams(route) {
        let uri = route.split("?");
        if (uri.length == 1) {
            return null;
        }
        else {
            let result: any = {}
            uri = uri[1];
            uri = uri.split("&");
            for (let params of uri) {
                let paramIdx = params.indexOf("=");
                let key = params.substring(0, paramIdx);
                let value = params.substring(paramIdx + 1);
                result[key] = decodeURIComponent(value);
            }
            return result;
        }
    }

    public navigationInterceptor(event: RouterEvent): void {

        if (event instanceof NavigationStart) {
            //this.blockUI.start('Carregando...');
            //if (!this.global.loggedUser()) {
                this.restartData(event.url);
            //}
        }

        if (event instanceof NavigationEnd) {
            //this.blockUI.stop()
        }

        // Set loading state to false in both of the below events to hide the spinner in case a request fails
        if (event instanceof NavigationCancel) {
            //this.blockUI.stop()
        }

        if (event instanceof NavigationError) {
            //this.blockUI.stop()
        }
    }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './material.module';
import { DateComponent } from './date/date.component';
import { SearchComponent } from './search/search.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { AsapCrudModule } from 'asap-crud';
import { AsapSkeletonModule } from 'asap-skeleton';
import { MatProgressSpinnerModule } from '@angular/material';
import { SharedService } from '../services/shared.service';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { ChartPeriodComponent } from './charts/period/chart-period.component';
import { ChartsModule } from 'ng2-charts';
import { ApiChartComponent } from './charts/api/api-chart.component';
import { FiltersComponent } from './filters/filters.component';
import { ReportSectionComponent } from './details/report/report-section.component';
import { PercentualItemComponent } from './details/percentual/percentual-item.component';
import { AgmCoreModule } from '@agm/core';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateSharedModule } from './translate/translate.module';
import { ModalLabelsChartComponent } from './charts/modal-labels-chart/modal-labels-chart.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Ng2IziToastModule } from 'ng2-izitoast';
import { SimpleNotificationsModule } from 'angular2-notifications';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    MatProgressSpinnerModule,
    NgxDaterangepickerMd.forRoot(),
    ChartsModule,
    AsapSkeletonModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCnQ7hg9qn8mrS3zSLX-xeXX3wKbuC2GXA'
    }),
    AgmJsMarkerClustererModule,
    TranslateModule,
    TranslateSharedModule,
    SimpleNotificationsModule.forRoot(),
    Ng2IziToastModule,
    AsapCrudModule
  ],
  declarations: [
    DateComponent,
    SearchComponent,
    NavbarComponent,
    SidebarComponent,
    ChartPeriodComponent,
    ApiChartComponent,
    ReportSectionComponent,
    PercentualItemComponent,
    FiltersComponent,
    ModalLabelsChartComponent
  ],
  providers: [
    SharedService
  ],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    Ng2IziToastModule,
    AsapCrudModule,
    AsapSkeletonModule,
    NgxDaterangepickerMd,
    MaterialModule,
    DateComponent,
    SearchComponent,
    NavbarComponent,
    SidebarComponent,
    ChartPeriodComponent,
    ApiChartComponent,
    ReportSectionComponent,
    PercentualItemComponent,
    FiltersComponent,
    AgmCoreModule,
    AgmJsMarkerClustererModule,
    TranslateSharedModule,
    NgxSkeletonLoaderModule
  ],
  entryComponents:[
    ModalLabelsChartComponent
  ]
})

export class SharedModule { }

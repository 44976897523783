import { Injectable } from '@angular/core';
import { AsapBaseService } from 'asap-crud';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';

declare const $:any;

@Injectable()
export class SharedService extends AsapBaseService {

    public title = "";

    public period  = {
        initial: moment().subtract(15,'days'),
        final: moment().subtract(1,'days')
    };

    public fieldFilter;

    public menu = [
        { icon: 'bi bi-graph-up-arrow',     name: 'Analytics',                bi: 'Analytics',              path: '/analytics' },
        { icon: 'bi bi-person-plus',        name: 'Cadastro',                 bi: 'Cadastro',               path: '/entries' },
        { icon: 'bi bi-bar-chart-steps',    name: 'Gamificação',              bi: 'Gamificação',            path: '/gamification' },
        { icon: 'bi bi-grid-1x2',           name: 'Portal',                   bi: 'Portal',                 path: '/portal' },
        { icon: 'bi bi-mortarboard',        name: 'EAD',                      bi: 'EAD',                    path: '/ead' },
        { icon: 'bi bi-mortarboard',        name: 'Performance',              bi: 'Performance',            path: '/performance' },
        { icon: 'bi bi-grid-1x2',           name: 'E-Commerce',               bi: 'E-Comerce',              path: '/e-commerce' },
        { icon: 'bi bi-mortarboard',        name: 'Courses',                  bi: 'Cursos',                 path: '/courses/cursos' },
        { icon: 'bi bi-mortarboard',        name: 'FreeCourses',              bi: 'Cursos Livres',          path: '/courses/cursos-livres' },
        { icon: 'bi bi-graph-up-arrow',     name: 'Evaluation',               bi: 'Avaliação',              path: '/avaliation/course-session' },
        // { icon: 'bi bi-mortarboard',     name: 'Area tecnica',             bi: 'Area tecnica',           path: '/courses/area-tecnica' },
        // { icon: 'bi bi-mortarboard',     name: 'Aulas rapidas forum',      bi: 'Aulas rapidas forum',    path: '/courses/forum' },
        // { icon: 'bi bi-mortarboard',     name: 'Aulas rapidas leitura',    bi: 'Aulas rapidas leitura',  path: '/courses/leitura' },
        { icon: 'bi bi-mortarboard',        name: 'CoursesBySession',         bi: 'Cursos por Sessão',      path: '/courses/course-session' },
        { icon: 'bi bi-mortarboard',        name: 'FastCoursesBySession',     bi: 'Aulas rapidas por Sessão', path: '/courses/aulas-rapidas-sessao' },
        // { icon: 'fa fa-address-book',    name: 'Cursos por Sessão',        bi: 'Cursos por Sessão',     path: '/course-session' },
        { icon: 'bi bi-globe',              name: 'Lider regional',           bi: 'Lider regional',        path: '/leader' },
        { icon: 'bi bi-lightbulb',          name: 'Forum',                    bi: 'Forum',                  path: '/forum' },
        { icon: 'bi bi-bullseye',           name: 'Jornadas',                 bi: 'Jornadas',               path: '/journeys' },
        { icon: 'bi bi-card-image',         name: 'Banner',                   bi: 'Banner',                 path: '/banners' },
        { icon: 'bi bi-play-circle',        name: 'Video',                    bi: 'Video',                  path: '/videos' },
        { icon: 'bi bi-question-circle',    name: 'Suporte',                  bi: 'Suporte',                path: '/suporte' },
        { icon: 'bi bi-calendar',           name: 'Evento',                   bi: 'Evento',                 path: '/events' },
        { icon: 'bi bi-whatsapp',           name: 'Whatsapp',                 bi: 'Whatsapp',               path: '/whatsapp' },
        { icon: 'bi bi-list',               name: 'Assessment',               bi: 'Assessment',             path: '/assessment' },
        { icon: 'bi bi-folder2-open',       name: 'Multicontas',              bi: 'Multicontas',            path: '/multicontas' },
        { icon: 'bi bi-newspaper',          name: 'News',                     bi: 'News',                   path: '/news' },
        { icon: 'bi bi-credit-card',        name: 'Pagamentos',               bi: 'Pagamento',              path: '/payment' },
        { icon: 'fa fa-handshake-o',        name: 'PFA',                      bi: 'PFA',                    path: '/pfa' },
        { icon: 'bi bi-people',             name: 'Mentoria',                 bi: 'Metoria',                path: '/mentoring' },
        { icon: 'bi bi-file-post',          name: 'Feed',                     bi: 'Feed',                   path: '/feed' },

    ];

    public reports = [
        {title:"Usuários Recorrentes", link:"/report/percentual/user-recurring", excel:"excel/user/recurring", api:"report/analytics/recurring", type: 'bar', visible: true, percentual: true},
        {title:"Ambientes mais acessados", link:"/report/percentual/hits-section", excel:"excel/user/hits-trails", api:"report/user/hits-trails", type: 'bar', visible: true, percentual: true},
        {title:"Usuários mais Pontuados", link:"/report/percentual/top-rated", excel:"excel/user/top-rated", api:"report/user/top-rated", type: 'bar', visible: true, percentual: false},
        {title:"Média de Pontuação", link:"/report/percentual/top-rated", excel:"excel/user/top-rated", api:"report/user/avg-points", type: 'bar', visible: true, percentual: false},
        {title:"Tipos de Acesso", link:"/report/percentual/devices", excel:"excel/user/devices", api:"report/user/devices", type: 'pie', visible: true, percentual: true},
        {title:"Usuários por Período", link:"/analytics/users-period", excel:"excel/user/access",api:"report/analytics/access", type: 'pie', visible: false, percentual: true}

    ];

    public percentualReports = [
        {title:"Regional", link:"leader-data", api:"report/regional/leader", masterByValue: true, detailByValue: false},
        {title:"Acessos", link:"access", api:"report/user/access", masterByValue: false, detailByValue: true},
        {title:"Usuários Recorrentes", link:"user-recurring", api:"report/user/recurring", masterByValue: false, detailByValue: false},
        {title:"Acessos por Dispositivo", link:"devices", api:"report/user/devices", masterByValue: false, detailByValue: false},
        {title:"Usuários mais Pontuados", link:"top-rated", api:"report/user/top-rated", masterByValue: true, detailByValue: false, afterValue: null},
        {title:"Ambientes mais acessados", link:"hits-section", api:"report/user/hits-trails", masterByValue: false, detailByValue: false},
        {title:"Portal: Usuários Recorrentes", link:"portal-recurring", api:"report/portal/recurring", masterByValue: false, detailByValue: false},
        {title:"Portal: Canais mais acessados", link:"hits-channels", api:"report/portal/hits-channels", masterByValue: false, detailByValue: false},
        {title:"Portal: Acessos", link:"portal-access", api:"report/portal/active", detailByValue: true},
        {title:"Cursos", link:"courses-rank", api:"report/trail/rank", excel:"excel/trail/rank", detailByValue: false},
    ];

    constructor(public http: HttpClient) {
        super(http);
    }

    public permission() {
        this.basePath = 'api/v2';
        this.entityName = 'report/permission';
        return this.getResources();
    }

    public moduleTitle() {
        if ($('.row h4:first').length > 0) {
            this.title = $('.row h4:first')[0].innerHTML;
            $('.row h4:first').hide();
        }
        return this.title;
    }


    public query() {
        let query = `dtInicial=${this.period.initial.format('YYYY-MM-DD')}&dtFinal=${this.period.final.format('YYYY-MM-DD')}`;
        if (this.fieldFilter) {
            query = query + `&field=${this.fieldFilter.field}&value=${this.fieldFilter.value}`;
        }
        return query;
    }

}

import { Component, OnChanges, SimpleChanges, Input } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Label, Color } from 'ng2-charts';
import * as moment from 'moment';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { TrailService } from 'src/app/services/trail.service';


@Component({
    selector: 'app-chart-period',
    templateUrl: './chart-period.component.html',
    providers: [TrailService]
})

export class ChartPeriodComponent implements OnChanges {

    @Input() query;
    @Input() title;
    @Input() api;
    @Input() type = 'line';

    @Input() data;

    public loading = true;

    public chartData: ChartDataSets[] = [
        { data: [0, 0, 0, 0, 0, 0, 0], label: '' },
    ];
    public chartLabels: Label[] = ['', '', '', '', '', '', ''];

    public chartOptions: (ChartOptions & { annotation: any }) = {
        responsive: true,
        tooltips: {
            custom: (a) => {
                // let tooltip:any = a;
                // if (tooltip.dataPoints) {
                //     if (tooltip.dataPoints.length > 0) {
                //         if (tooltip.dataPoints[0].label.indexOf(':') >= 0) {
                //             tooltip.body[0].lines[0] = tooltip.dataPoints[0].value + '%';
                //             tooltip.title[0] = tooltip.dataPoints[0].label + ' horas';
                //         }
                //     }
                // }
            }
        },
        legend: {
            display: false,
            position: 'left',
            labels: {
                fontSize: 9,
                usePointStyle: true,
                boxWidth: 5
            }
        },
        scales: {
            // We use this empty structure as a placeholder for dynamic theming.
            xAxes: [{}],
            yAxes: [
                {
                    id: 'y-axis-0',
                    position: 'left',
                    beforeBuildTicks: (scale) => {
                        scale.max = scale.max * 1.2;
                        scale.min = 0;
                    }
                },
            ]
        },
        plugins: {
            datalabels: {
                formatter: (value, ctx) => {
                    return '';
                },
            },
        },
        annotation: {
            // annotations: [
            //     {
            //         type: 'line',
            //         mode: 'vertical',
            //         scaleID: 'x-axis-0',
            //         value: 'March',
            //         borderColor: 'orange',
            //         borderWidth: 2,
            //         label: {
            //             enabled: true,
            //             fontColor: 'orange',
            //             content: 'LineAnno'
            //         }
            //     },
            // ],
        },
    };

    public chartColors: Color[] = [
        {
            backgroundColor: 'rgba(148,159,177,0.2)',
            borderColor: 'rgba(148,159,177,1)',
            pointBackgroundColor: 'rgba(148,159,177,1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(148,159,177,0.8)'
        }
    ];

    public chartLegend = true;
    public chartType = 'line';
    //public lineChartPlugins = [ pluginAnnotations ];

    constructor(public service: TrailService) {
        this.service.basePath = 'api/v21';
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.query || changes.api) {
            this.loadData();
        }
        if (changes.data) {
            this.chartLabels = [];
            this.chartData[0].data = [];
            this.data.map((item: any) => {
                this.chartLabels.push(item.label);
                this.chartData[0].data.push(item.value);
            });
        }
        if (changes.type) {
            this.chartType = this.type;
        }
    }

    public loadData() {
        this.loading = true;
        this.service.entityName = this.api;
        this.service.getResources({ query: this.query }).subscribe((response: any) => {
            this.chartLabels = [];
            this.chartData[0].data = [];
            response.data.map((item: any) => {
                this.chartLabels.push(item.label);
                this.chartData[0].data.push(item.value);
            });
            this.loading = false;
        });
    }

}


import { Routes } from '@angular/router';
import { ReportSectionComponent } from './shared/details/report/report-section.component';
import { PercentualItemComponent } from './shared/details/percentual/percentual-item.component';
import { ForbidenComponent } from './auth-guard/forbiden.component';
import { AuthGuardService } from './services/auth-guard.service';
import { DashboardComponent } from './dashboard.component'; 
import { FeedComponent } from './feed/feed.component';

export const AppRoutes: Routes = [
    {
        path: 'forbiden',
        component: ForbidenComponent,
        canActivate: [ AuthGuardService ]
    },
    {
        path: '',
        component: DashboardComponent,
        canActivate: [AuthGuardService],
        children: [
            {
                path: '',
                canLoad: [AuthGuardService],
                loadChildren: './users/users.module#UsersModule'
            },
            {
                path: '',
                canLoad: [AuthGuardService],
                loadChildren: './portal/portal.module#PortalModule'
            },
            {
                path: '',
                canLoad: [AuthGuardService],
                loadChildren: './whatsapp-interaction/whatsapp.module#WhatsappInteractionModule'
            },
            {
                path: '',
                canLoad: [AuthGuardService],
                loadChildren: './ead/ead.module#EADModule'
            },
            {
                path: '',
                canLoad: [AuthGuardService],
                loadChildren: './performance/performance.module#PerformanceModule'
            },
            {
                path: '',
                canLoad: [AuthGuardService],
                loadChildren: './courses/courses.module#CoursesModule'
            },
            {
                path: '',
                canLoad: [AuthGuardService],
                loadChildren: './journeys/journeys.module#JourneysModule'
            },
            {
                path: '',
                canLoad: [AuthGuardService],
                loadChildren: './comments/comments.module#CommentsModule'
            },
            {
                path: '',
                canLoad: [AuthGuardService],
                loadChildren: './profile/profile.module#ProfileModule'
            },
            {
                path: '',
                canLoad: [AuthGuardService],
                loadChildren: './leader/leader.module#LeaderModule'
            },
            {
                path: '',
                canLoad: [AuthGuardService],
                loadChildren: './forum/forum.module#ForumModule'
            },
            {
                path: '',
                canLoad: [AuthGuardService],
                loadChildren: './avaliation/avaliation.module#AvaliationModule'
            },
            {
                path: '',
                canLoad: [AuthGuardService],
                loadChildren: './banners/banners.module#BannersModule'
            },
            {
                path: '',
                canLoad: [AuthGuardService],
                loadChildren: './events/events.module#EventsModule'
            },
            {
              path: '',
              canLoad: [AuthGuardService],
              loadChildren: './assessment/assessment.module#AssessmentModule'
            },
            {
              path: '',
              canLoad: [AuthGuardService],
              loadChildren: './multi-contas/multi-contas.module#MultiContasModule'
            },
            {
              path: '',
              canLoad: [AuthGuardService],
              loadChildren: './news/news.module#NewsModule'
            },
            {
                path: '',
                canLoad: [AuthGuardService],
                loadChildren: './videos/videos.module#VideosModule'
            },
            {
                path: '',
                canLoad: [AuthGuardService],
                loadChildren: './suporte/suporte.module#SuporteModule'
            },
            {
                path: '',
                canLoad: [AuthGuardService],
                loadChildren: './entries/entries.module#EntriesModule'
            },
            {
              path: '',
              canLoad: [AuthGuardService],
              loadChildren: './mentoring/mentoring.module#MentoringModule'
            },
            {
                path: '',
                canLoad: [AuthGuardService],
                loadChildren: './gamification/gamification.module#GamificationModule'
            },
            {
                path: '',
                canLoad: [AuthGuardService],
                loadChildren: './e-commerce/e-commerce.module#ECommerceModule'
            },
            {
                path: '',
                loadChildren: './payment/payment.module#PaymentModule'
            },
            {
                path: 'pfa',
                canLoad: [AuthGuardService],
                loadChildren: './acessos-teste/acessos.module#AcessosModule'
            },
            {
                path: 'feed',
                canLoad: [AuthGuardService],
                component: FeedComponent
            },
            {
                path: 'report/data',
                canLoad: [AuthGuardService],
                component: ReportSectionComponent,
                children: [
                    {
                        path: '**',
                        component: PercentualItemComponent
                    }
                ]
            },
            {
                path: 'report/percentual',
                canLoad: [AuthGuardService],
                children: [
                    {
                        path: '**',
                        component: PercentualItemComponent
                    }
                ]

            }
        ]
    }
];

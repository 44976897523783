import { Component } from '@angular/core';
import { SharedService } from 'src/app/services/shared.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html'
})

export class NavbarComponent {

    constructor(public service: SharedService) {
        
    }

    public doExit() {
        window.close();
    }

}

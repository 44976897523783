import { AsapBaseService, Global } from 'asap-crud';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import * as moment from 'moment';
import * as fileSaver from 'file-saver';
import { TranslateSharedModule } from '../shared/translate/translate.module';

@Injectable()
export class UserService extends AsapBaseService {

    public extraOptions: any = {};

    public period = {
        initial: moment().subtract(15, 'days'),
        final: moment().subtract(1, 'days')
    };

    public fieldFilter;
  trailComments: any;
  trailList: any;

    constructor(
        public http: HttpClient,
        public global: Global,
        private translateShared: TranslateSharedModule
    ) {
        super(http);
        this.basePath = 'api/v21';
    }

    protected options(): any {
        let options = super.options();

        for (let key in this.extraOptions) {
            options[key] = this.extraOptions[key];
        };

        console.log("Options", options);

        return options;
    }

    public user(id) {
        this.basePath = 'api/v2';
        this.entityName = 'admin/asap-user';

        let result = this.getEntity(id);

        this.basePath = 'api/v21';

        return result;
    }

    public userData(id) {
        this.basePath = 'api/v2';
        this.entityName = 'admin/asap-user/dados';

        let result = this.getEntity(id);

        this.basePath = 'api/v21';

        return result;
    }

    public profile(id) {
        this.basePath = 'api/v21';
        this.entityName = 'report/user/profile';

        let result = this.getEntity(id);

        return result;
    }

 
    public performance(trail_id, user_id) {
        this.basePath = 'api/v2/client';
        this.entityName = `trilha/performance/trail/${trail_id}/${user_id}`;

        let result = this.getResources();

        return result;
    }

    public permission(id) {
        this.basePath = 'api/v2';
        this.entityName = 'admin/permission';

        let result = this.getEntity(id);

        this.basePath = 'api/v21';

        return result;
    }

    public getFile(url, title = null, query = null, basePath = 'api/v21'): void {
        this.basePath = basePath;
        this.entityName = url;
        this.extraOptions["responseType"] = "text";

        if (title == null) {
            title = 'report';
        }


        console.log("title=", title);
        title = this.translateShared.showTranslator(title);
        console.log("title2=", title);

        let filename = title.__zone_symbol__value + ".xls";

        if (query != null) {
            if (this.entityName.indexOf("?") > 0) {
                this.entityName += "&" + query;
            }
            else {
                this.entityName += "?" + query;
            }
        }

        if (this.global.loggedUser() && this.global.loggedUser().client_id) {
            if (this.entityName.indexOf("?") > 0) {
                this.entityName += "&client_id=" + this.global.loggedUser().client_id;
            }
            else {
                this.entityName += "?client_id=" + this.global.loggedUser().client_id;
            }
        }

        // this.entityName = this.entityName + "?&title=" + title;

        this.global.startWaiting("Gerando arquivo...");
        this.getResources().subscribe(
            (response) => {
                console.log("getFile", response);
                // It is necessary to create a new blob object with mime-type explicitly set
                // otherwise only Chrome works like it should
                const newBlob = new Blob([(response)], { type: 'application/ms-excel' });

                // IE doesn't allow using a blob object directly as link href
                // instead it is necessary to use msSaveOrOpenBlob
                // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                //     window.navigator.msSaveOrOpenBlob(newBlob, filename);
                //     return;
                // }

                // For other browsers:
                // Create a link pointing to the ObjectURL containing the blob.
                const downloadURL = URL.createObjectURL(newBlob);
                fileSaver.saveAs(downloadURL, filename);
                this.global.stopWaiting();
            },
            (error) => {
                console.log("Error", error);
                this.global.stopWaiting();
            }
        );
        this.extraOptions = {};
    }

    public getFileCustomFields(url, title = null, type = null, fields, basePath = 'api/v21'): void {
        this.basePath = basePath;
        this.entityName = url;
        this.extraOptions.responseType = "text";

        if (title == null) {
            title = 'report';
        }


        console.log("title=", title);
        title = this.translateShared.showTranslator(title);
        console.log("title2=", title);

        let filename = title.__zone_symbol__value + ".xls";

        // this.entityName = this.entityName + "?&title=" + title;

        this.global.startWaiting("Gerando arquivo...");
        this.createResource(fields).subscribe(
            (response) => {
                console.log("getFile", response);
                // It is necessary to create a new blob object with mime-type explicitly set
                // otherwise only Chrome works like it should
                const newBlob = new Blob([(response)], { type: 'application/ms-excel' });

                // IE doesn't allow using a blob object directly as link href
                // instead it is necessary to use msSaveOrOpenBlob
                // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                //     window.navigator.msSaveOrOpenBlob(newBlob, filename);
                //     return;
                // }

                // For other browsers:
                // Create a link pointing to the ObjectURL containing the blob.
                const downloadURL = URL.createObjectURL(newBlob);
                fileSaver.saveAs(downloadURL, filename);
                this.global.stopWaiting();
            },
            (error) => {
                console.log("Error", error);
                this.global.stopWaiting();
            }
        );
        this.extraOptions = {};
    }

    public getFileOnAdonis(url, title = null, query = null): void {
        this.extraOptions["responseType"] = "text";

        if (title == null) {
            title = 'report';
        }

        title = this.translateShared.showTranslator(title);

        let filename = title.__zone_symbol__value + ".xls";

        if (query != null) {
            if (this.entityName.indexOf("?") > 0) {
                this.entityName += "&" + query;
            }
            else {
                this.entityName += "?" + query;
            }
        }

        if (this.global.loggedUser() && this.global.loggedUser().client_id) {
            if (this.entityName.indexOf("?") > 0) {
                this.entityName += "&client_id=" + this.global.loggedUser().client_id;
            }
            else {
                this.entityName += "?client_id=" + this.global.loggedUser().client_id;
            }
        }

        // this.entityName = this.entityName + "?&title=" + title;

        this.global.startWaiting("Gerando arquivo...");
        this.adonis('','',this.extraOptions).get(url).subscribe(
            (response:any) => {
                console.log("getFile", response);
                // It is necessary to create a new blob object with mime-type explicitly set
                // otherwise only Chrome works like it should
                const newBlob = new Blob([(response.data)], { type: 'application/ms-excel' });

                // IE doesn't allow using a blob object directly as link href
                // instead it is necessary to use msSaveOrOpenBlob
                // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                //     window.navigator.msSaveOrOpenBlob(newBlob, filename);
                //     return;
                // }

                // For other browsers:
                // Create a link pointing to the ObjectURL containing the blob.
                const downloadURL = URL.createObjectURL(newBlob);
                fileSaver.saveAs(downloadURL, filename);
                this.global.stopWaiting();
            },
            (error) => {
                console.log("Error", error);
                this.global.stopWaiting();
            }
        );
        this.extraOptions = {};
    }

    public query() {
        let query = `dtInicial=${this.period.initial.format('YYYY-MM-DD')}&dtFinal=${this.period.final.format('YYYY-MM-DD')}`;
        if (this.fieldFilter) {
            query = query + `&field=${this.fieldFilter.field}&value=${this.fieldFilter.value}`;
        }
        return query;
    }

}

import { Injectable, OnInit } from '@angular/core';
import { Router, CanActivate, ActivatedRoute, CanLoad, Route, UrlSegment } from '@angular/router';
import { AuthService } from './auth.service';
import { Global } from 'asap-crud';
import { Observable } from 'rxjs';

declare var swal: any;

@Injectable()
export class AuthGuardService implements CanLoad, CanActivate {

    constructor(
        public global: Global,
        public auth: AuthService,
        public router: Router,
        public route: ActivatedRoute
    ) {
        console.log('[AuthGuardService]',new Date())
    }

    canLoad(route: Route, segments: UrlSegment[]): boolean | Observable<boolean> | Promise<boolean> {
        // console.log('CanLoad:', segments);
        // console.log('CanLoad QueryString:', this.auth.params);
        // console.log('CanLoad LoggedUser:', this.global.loggedUser());
        if (this.global.loggedUser()) {
            localStorage.removeItem('client');  
            if (['forbiden'].indexOf(route.path) >= 0) {                   
                this.router.navigate(['/analytics']);
                return false;
            }
            return true;
        } 
        else {
            if (this.auth.params) {
                const brand = this.auth.params.brand;
                if (this.auth.params.token) {                                     
                    return new Promise((resolve) => {
                        this.auth.setSessionToken({token:this.auth.params.token}).then(
                            (response) => {
                                localStorage.setItem('client',this.global.loggedUser().client_id);
                                let route = segments.map((item) => item.path);
                                this.router.navigate(route);
                                resolve(true);
                            },
                            (error) => {
                                this.router.navigate(['/forbiden']);
                                resolve(false);
                            }

                        );
                    });
                }
            } 
            else {
                if (['forbiden'].indexOf(route.path) >= 0) {
                    return true;
                }
                this.router.navigate(['/forbiden']);
                return false;
            }
        }
    }

    canActivate(): Observable<boolean> | boolean {
        const clientId = sessionStorage.getItem('client');
        // console.log('canActivate:', this.router.url);
        // console.log('canActivate QueryString:', this.auth.params);
        if (this.global.loggedUser()) {
            if (['forbiden'].indexOf(this.router.url) >= 0) {
                this.router.navigate(['/analytics']);
            }
            return true;
        }
        else if ((this.auth.params) && (this.auth.params.token)) {
            return true;
        }
        else {
            if (['/forbiden','/'].indexOf(this.router.url) >= 0) {
                return true;
            }
            return false;
        }
        // const verify = this.auth.verifyClient(+clientId).map(res => {

        //     console.log('AuthGuard.CanActivate: Verify ', res);

        //     if (res.success) {
        //         if (['active-directory', 'login', 'recovery'].indexOf(this.router.url) >= 0) {
        //             this.router.navigate(['/home']);
        //         }
        //         return true;
        //     } else {
        //         this.router.navigate(['/erros/403']);
        //     }
        // }).first();

        // return verify;
    }

    errorNotificate(msg: string): any {
        swal({
            title: 'Erro',
            text: msg,
            type: 'warning',
            confirmButtonClass: 'btn btn-success',
        });
    }
}

import { Component, OnInit } from '@angular/core';
import { AuthService } from './services/auth.service';

declare const $: any;

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit {

    constructor(public auth: AuthService) {

    }

    ngOnInit() {

    }

}

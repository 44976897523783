import { Component, Output, EventEmitter, ViewChild, OnInit } from '@angular/core';
import * as moment from 'moment';
import { DaterangepickerComponent } from 'ngx-daterangepicker-material';


@Component({
    selector: 'app-date',
    templateUrl: './date.component.html',
    styleUrls: ['./date.component.css']
})

export class DateComponent implements OnInit {

    @ViewChild("dateRange",{static:true}) dateRange: DaterangepickerComponent;

    @Output() periodChange = new EventEmitter();

    public moment = moment;
    public selectedPeriod;

    public period = [
        {label: 'Últimos 7 dias', initial: moment().subtract(7, 'days'), final: moment().subtract(1,'days')},
        {label: 'Últimos 15 dias', initial: moment().subtract(15, 'days'), final: moment().subtract(1,'days')},
        {label: 'Últimos 30 dias', initial: moment().subtract(30, 'days'), final: moment().subtract(1,'days')},
    ]

    public range = {
        label: "Customizado",
        initial: null,
        final: null
    }

    daterangepickerOptions = {
        startDate: '09/01/2017',
        endDate: '09/02/2017',
        format: 'DD/MM/YYYY'
    }

    constructor() {
        
    }

    ngOnInit() {
        this.changeDate(this.period[1]);
    }

    public changeDate(period) {
        this.selectedPeriod = period;
        this.periodChange.emit(period);
        if (this.dateRange) {
            this.dateRange.setStartDate(period.initial);
            this.dateRange.setEndDate(period.final);
        }
    }

    public setRange(range) {
        console.log("Range",range);
        //this.range.label = `de ${range.startDate.format("DD/MM/YYYY")} até ${range.endDate.format("DD/MM/YYYY")}`;
        this.range.initial = range.startDate;
        this.range.final = range.endDate;
        this.changeDate(this.range);
    }
}

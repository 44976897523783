import { Component, Input, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'src/app/services/shared.service';
import { UserService } from 'src/app/services/user.service';
import { Location } from '@angular/common';

@Component({
    selector: 'app-report-section',
    templateUrl: './report-section.component.html',
    providers: [ /*UserService*/ ]
})
export class ReportSectionComponent {

    public static reports = [];

    public report;
    public period;

    public loading;
    public columns;
    public data;

    constructor(
        public service: SharedService,
        public userService: UserService,
        public route: ActivatedRoute,
        public router: Router,
        public location: Location
    ) {
        this.service.basePath = 'api/v21';
        console.log("Report: ", ReportSectionComponent.reports, router.url);
        let report = ReportSectionComponent.reports.find((r) => r.link == router.url);
        if (report) {
            this.report = report;
            this.loadData(this.service.period);
        }
        else {
            this.router.navigate(['/']);
        }
    }

    public loadData(period) {
        if (this.report.api) {
            this.service.period = period;
            this.loading = true;
            this.service.entityName = this.report.api;
            this.service.getResources({query: this.service.query() + "&detail"}).subscribe((response:any) => {
                if (response.data.length > 0) {
                    this.columns = Object.keys(response.data[0]);
                    this.data = response.data;
                }
                this.loading = false;
            });
        }
    }

    public back() {
        this.location.back();
    }

}

import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ChartType, ChartOptions, ChartDataSets } from 'chart.js';
import { Label, Color } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { AsapService } from 'asap-crud';
import { UserService } from 'src/app/services/user.service';
import { MatDialog } from '@angular/material';
import { ModalLabelsChartComponent } from '../modal-labels-chart/modal-labels-chart.component';

@Component({
    selector: 'app-api-chart',
    templateUrl: './api-chart.component.html',
    styleUrls: ['./api-chart.component.scss'],
    providers: [ /*UserService*/]
})

export class ApiChartComponent implements OnChanges {

    @Input() type = 'pie';
    @Input() title;
    @Input() link;
    @Input() basePath = 'api/v21';
    @Input() api;
    @Input() excel;
    @Input() period;
    @Input() percentual = true;
    @Input() chartHeight = '250px';
    @Input() chartWidth = '100%';
    @Input() extraButtons = [];
    @Input() fields = false;

    public loading = true;

    // Pie
    public pieChartOptions: ChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            position: 'left',
            labels: {
                fontSize: 9,
                usePointStyle: true,
                boxWidth: 5
            }
        },
        plugins: {
            datalabels: {
                formatter: (value, ctx) => {
                    if (this.percentual) {
                        return value.toFixed(0) + '%';
                    }
                    else {
                        return value;
                    }
                },
            },
        }
    };
    public pieChartLabels: Label[] = [['Download', 'Sales'], ['In', 'Store', 'Sales'], 'Mail Sales'];
    public pieChartData: number[] = [300, 500, 100];
    public pieChartType: ChartType = 'pie';
    public pieChartLegend = true;
    public pieChartColors = [
        // {
        //     backgroundColor: ['rgba(255,0,0,0.3)', 'rgba(0,255,0,0.3)', 'rgba(0,0,255,0.3)'],
        // },
    ];

    public pieChartPlugins = [pluginDataLabels];

    // Bar
    public barChartOptions: ChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
            custom: (a) => {
                a["title"] = [''];
            }
        },
        legend: {
            position: 'left',
            labels: {
                fontSize: 9,
                usePointStyle: true,
                boxWidth: 5
            }
        },
        // We use these empty structures as placeholders for dynamic theming.
        scales: {
            xAxes: [{}],
            yAxes: [
                {
                    id: 'y-axis-0',
                    position: 'left',
                    display: false,
                    ticks: {
                        display: false,
                    },
                    beforeBuildTicks: (scale) => {
                        scale.max = scale.max * 1.2;
                        scale.min = 0;
                    }
                }
            ]
        },
        plugins: {
            datalabels: {
                anchor: 'end',
                align: 'end',
                formatter: (value, ctx) => {
                    if (this.percentual) {
                        return value.toFixed(0) + '%';
                    }
                    else {
                        return value;
                    }
                },
            }
        }
    };
    public barChartLabels: Label[] = ['2006', '2007', '2008', '2009', '2010', '2011', '2012'];
    public barChartType: ChartType = 'bar';
    public barChartLegend = true;
    public barChartPlugins = [pluginDataLabels];

    public barChartData: ChartDataSets[] = [
        { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A' },
        { data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B' }
    ];

    public barChartColors: Color[] = [
        { backgroundColor: '#3f51b5' },
        { backgroundColor: '#2496f6' },
        { backgroundColor: '#ff9800' },
        { backgroundColor: '#e257b5' },
        { backgroundColor: '#e9d74c' },
    ];

    constructor(
        public service: UserService,
        public dialog: MatDialog
    ) {

    }

    public ngOnChanges(changes: SimpleChanges) {
        if ((changes.period) || (changes.basePath) || (changes.api)) {
            //console.log('Changes',changes);
            this.loadData();
        }
    }

    public loadData() {
        if (this.api) {
            this.loading = true;
            this.service.basePath = this.basePath;
            this.service.entityName = this.api;
            this.service.getResources({ query: this.period }).subscribe((response: any) => {
                this.pieChartLabels = [];
                this.pieChartData = [];
                this.barChartLabels = [];
                this.barChartData = [];
                if (response.data.length > 0) {
                    let total = response.data.reduce((total, item) => {
                        total = {
                            value: parseInt(total.value) + parseInt(item.value)
                        }
                        return total;
                    });

                    response.data.map((item: any) => {
                        let value = item.value;
                        if (this.percentual) {
                            value = Math.round(100 * parseInt(item.value) / total.value);
                        }

                        if (this.type == 'pie') {
                            this.pieChartLabels.push(item.label);
                            this.pieChartData.push(value);
                        }
                        else if (this.type == 'bar') {
                            this.barChartData.push({
                                data: [value],
                                label: item.label
                            })
                        }
                    });
                }
                this.loading = false;
            });
        }
    }


    public openModalLabelsChart(item): void {
        const dialogRef = this.dialog.open(ModalLabelsChartComponent, {
            width: '600px',
            data: {
                excel: this.excel,
                title: this.title
            }
        });
    }

}

import { AsapBaseService } from 'asap-crud';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import * as moment from 'moment';

@Injectable()
export class TrailService extends AsapBaseService {

    public extraOptions:any = {};

    public types = [
        {name:"cursos",title:"Courses",type:"0"},
        {name:"cursos-livres",title:"FreeCourses",type:"6"},
        {name:"area-tecnica",title:"Área Técnica",type:"2"},
        {name:"forum",title:"Aulas Rápidas: Fórum",type:"3"},
        {name:"leitura",title:"Aulas Rápidas: Leitura",type:"4"},
        {name:"course-session",title:"CoursesBySession",type:"8"},
        {name:"aulas-rapidas-sessao",title:"FastCoursesBySession",type:"9"},

    ]

    public period  = {
        initial: moment().subtract(15,'days'),
        final: moment().subtract(1,'days')
    };

    public fieldFilter;

    public reports = [
        {title:"Usuários Recorrentes", link:"users-recurring", excel:"excel/user/recurring", api:"report/user/recurring", type: 'bar', visible: true, percentual: true},
        {title:"Ambientes mais acessados", link:"hits-section", excel:"report/user/hits-trails", api:"report/user/hits-trails", type: 'bar', visible: true, percentual: true},
        {title:"Usuários mais Pontuados", link:"top-rated", excel:"excel/user/top-rated", api:"report/user/top-rated", type: 'bar', visible: true, percentual: false},
        {title:"Tipos de Acesso", link:"devices-type", excel:"excel/user/devices", api:"report/user/devices", type: 'pie', visible: true, percentual: true},
        {title:"Usuários por Período", link:"users-period", excel:"excel/user/access",api:"report/user/access", type: 'pie', visible: false, percentual: true}
    ]
  startWaiting: any;
  stopWaiting: any;

    constructor(public http: HttpClient) {
        super(http);
        this.basePath = 'api/v21';
    }

    public query() {
        let query = `dtInicial=${this.period.initial.format('YYYY-MM-DD')}&dtFinal=${this.period.final.format('YYYY-MM-DD')}`;
        if (this.fieldFilter) {
            query = query + `&field=${this.fieldFilter.field}&value=${this.fieldFilter.value}`;
        }
        return query;
    }

    public trailData(id) {
        this.basePath = "api/v2/client";
        this.entityName = "trilha/detalhe";
        return this.getResource(id);        
    }

    public trailSummary(type, period) {
        this.basePath = "api/v21";
        this.entityName = `report/trail/summary/${type}?dtInicial=${period.initial.format('YYYY-MM-DD')}&dtFinal=${period.final.format('YYYY-MM-DD')}`;
        return this.getResources();
    }


    public trailList(type,page=1,course,query) {
        this.basePath = "api/v21";
        this.entityName = "report/trail/list/" + type;
        if (course && query) {
            return this.getResources({query:`page=${page}&course=${course}&${query}`}); 
           
        }
        else if (course) {
            return this.getResources({query:`page=${page}&course=${course}`}); 
        }
        else if (query) {        
            return this.getResources({query:`page=${page}&${query}`}); 
        }
        else {
            return this.getResources({query:`page=${page}`}); 
        }
    }

    public trailInfo(trailID,userID) {
        this.basePath = "api/v21";
        this.entityName = "report/trail/info/" + trailID;
        return this.getResource(userID); 
    }

    public trailComments(trail_id,count,page=1,starsFilter='todos') {
        let query = `page=${page}`;

        this.basePath = "api/v21";
        this.entityName = "report/trail/comments/" + trail_id + "/" + count;

        if (starsFilter != 'todos') {
            query = `${query}&stars=${starsFilter}`;
        }

        return this.getResources({query:query}); 
    }
}
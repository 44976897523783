
import { Component } from '@angular/core';
import { SharedService } from 'src/app/services/shared.service';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

declare const $: any;

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html'
})
export class SidebarComponent {

    public permission;

    constructor(
        public service: SharedService,
        public userService: UserService,
        public router: Router,

    ) {
        this.service.permission().subscribe((response: any) => {
            console.log("Permission", response);
            this.permission = response.data;
            for (const menu of this.service.menu) {
                if (this.router.url == '/') {
                    if (this.isPermitted(menu.bi)) {
                        this.router.navigate([menu.path]);
                        break;
                    }
                }
            }    
        });
    }

    public isPermitted(menu) {
        if (this.permission) {
            let m = this.permission.find(item => item.bi.toLowerCase() == menu.toLowerCase());
            if (m) {
                return m.status;
            }
        }
        return false;
    }

    ngOnInit() {
        $(document).ready(function () {

            $('#sidebarCollapse').on('click', function () {
                $('#sidebar, #content, .navbar').toggleClass('active');
            });

            $(document).on('click', '#btn-h', function () {
                $(this).attr("id", "btn-v").html("<i class='bi bi-three-dots'></i>");
            });

            $(document).on('click', '#btn-v', function () {
                $(this).attr("id", "btn-h").html("<i class='bi bi-three-dots-vertical'></i>");
            });

        });
    }

    public hasLanguage() {
        return sessionStorage.getItem('language');
    }



}

import { Component, Output, EventEmitter, Input } from '@angular/core';
import { AsapService } from 'asap-crud';

declare const $:any;

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: [ './search.component.scss' ],
    providers: [ AsapService ]
})

export class SearchComponent {

    @Output() onSelect = new EventEmitter();
    @Input() id = 'filter';

    public loadingData = false;

    public fields;
    public selectedField;
    public filter:any;

    constructor(public service: AsapService) {
        this.service.basePath = "api/v2/admin";
        this.service.entityName = "folder/permission";

        this.service.getResources().subscribe((response) => {
            this.fields = response;
            if (this.fields.length > 0) {
                this.loadField(this.fields[0]);
            }
        });
    }

    public loadField(field) {
        this.loadingData = true;
        if (!field.data) {
            this.service.createResource({filter:[field.name]}).subscribe((response) => {
                field.data = response[0].data.options;
                this.selectedField = field;
                this.loadingData = false;
            });
        }
        else {
            this.selectedField = field;
            this.loadingData = false;
        }
    }

    public selectData(data) {
        if (data) {
            this.filter = {
                field: this.selectedField.name,
                value: data
            };
            console.log("selectData() 1",this.filter);
            this.onSelect.emit(this.filter);
        }
        else {
            console.log("selectData() 2",this.filter);
            this.filter = null;
            this.onSelect.emit(null);
        }
    }

    public open() {
        this.id = this.id.toString();
        if (this.id) {
            $('#id_'+this.id).modal('show');
        }
    }

}

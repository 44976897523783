import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Global, AsapService } from 'asap-crud';
import { UserService } from 'src/app/services/user.service';


@Component({
  selector: 'app-modal-labels-chart',
  templateUrl: './modal-labels-chart.component.html',
  styleUrls: ['./modal-labels-chart.component.scss']
})
export class ModalLabelsChartComponent implements OnInit {

  public fields;
  public loading = false;
  public url;

  public entity = {
    pessoal: '',
    geral: ''
  };

  trail_id: string;

  constructor(
    public dialogRef: MatDialogRef<ModalLabelsChartComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public global: Global,
    public asapService: AsapService,
    public service: UserService) { }

  ngOnInit() {
    console.log('[ModalLabelsChartComponent] -> data', this.data);
    this.getData();
    if (this.data.url) {
      this.url = this.data.url;
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  getData() {
    this.loading = true;
    this.asapService.basePath = 'api/v21/excel';
    this.asapService.entityName = `filter?type=${this.data.type}&client_id=&trail_id=${this.data.item.id}`;
    this.asapService.getResources().subscribe(response => {
      this.fields = response.data;
      this.loading = false;
    });
   }

  getCustomFile() {
    let url;
    if (this.url) {
      url = this.url;
    }
    else {
      if (this.data.type == 'journey') {
        if (this.data.journeyID) {
          url = 'excel/jornada/trail/' + this.data.item.id + '/' + this.data.journeyID;
        }
        else {
          url = 'excel/jornada/geral/' + this.data.item.id;
        }
      }
      else if (this.data.type == 'trail') {
        url = `excel/trail/rank/${this.data.item.id}`;
      }
  
      if (this.data.query) {
        if (url.indexOf("?") > 0) {
          url += "&" + this.data.query;
        }
        else {
          url += "?" + this.data.query;
        }
      }
    }
    this.service.getFileCustomFields(url, 'Relatório', this.data.type, this.entity);
    this.dialogRef.close();
  }

}
